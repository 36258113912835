<template>
  <v-data-table-server
    v-model:sort-by="table.options.value.sortBy"
    v-model:items-per-page="table.options.value.itemsPerPage"
    v-model:page="table.options.value.page"
    must-sort
    data-testid="electronic-applications-table"
    :mobile="null"
    mobile-breakpoint="sm"
    :headers="table.tableHeaders.value"
    :items="table.mappedItems.value"
    :items-length="table.itemsLength.value"
    :loading="table.loading.value"
    :footer-props="table.footerProps.value"
    :items-per-page-options="table.itemsPerPageOptions"
    @update:options="updateOptions"
  >
    <template #top>
      <v-row class="ma-0" align="center" dense>
        <h1 class="text-h5 pa-3">eApps</h1>
        <v-spacer />
        <v-col cols="12" md="auto" order-md="1" order="2">
          <v-row class="ma-0" justify="end" align="center">
            <table-stats :stats="stats" />
          </v-row>
        </v-col>
        <v-col cols="auto" order-md="2" order="1">
          <v-tooltip v-if="table.filter.value.view !== 'all'" location="top">
            <template #activator="{ props: templateProps }">
              <app-button
                :icon="mdiFileExport"
                variant="text"
                density="comfortable"
                class="table-btn"
                v-bind="templateProps"
                data-testid="electronic-applications-export"
                @click="confirmAndEmailData"
              />
            </template>
            <span>Email Data</span>
          </v-tooltip>
          <v-tooltip location="top">
            <template #activator="{ props: templateProps }">
              <app-button
                :icon="mdiRefresh"
                variant="text"
                density="comfortable"
                class="table-btn"
                v-bind="templateProps"
                data-testid="electronic-applications-refresh"
                @click="getData"
              />
            </template>
            <span>Refresh</span>
          </v-tooltip>
        </v-col>
      </v-row>
      <v-divider />
      <table-filter
        :header-props="{
          class: 'mt-0 pa-3',
          rounded: false
        }"
        :model-value="table.filter.value"
        :headers="table.filterHeaders.value"
        :loading="table.loading.value"
        @update="updateFilter"
      />
      <v-divider />
    </template>

    <template #bottom>
      <table-footer
        v-model:page="table.options.value.page"
        v-model:items-per-page="table.options.value.itemsPerPage"
        :items-per-page-options="table.itemsPerPageOptions"
        :items-length="table.itemsLength.value"
      />
    </template>

    <template #[`item.insuredName`]="{ item }">
      <router-link
        :to="{
          name: 'ElectronicApplicationView',
          params: {
            id: item.additional.eAppId
          }
        }"
        class="text-none"
      >
        {{ item.insuredName }}
      </router-link>
    </template>
    <template #[`item.created`]="{ item }">
      <timestamp-formatter :model-value="item.created" />
    </template>
    <template #[`item.website`]="{ item }">
      <div
        :class="{
          'truncate-400': mdAndUp,
          'truncate-200': smAndDown
        }"
      >
        {{ item.website }}
      </div>
    </template>
    <template #[`item.premium`]="{ item }">
      {{ premiumText(item.additional) }}
      <div class="text-grey"> ({{ modeText(item.additional) }}) </div>
    </template>
  </v-data-table-server>
</template>

<script setup>
import TimestampFormatter from "@/components/shared/formatters/TimestampFormatter.vue";

import TableFilter from "@/components/shared/data-table/TableFilter.vue";
import TableStats from "@/components/shared/data-table/TableStats.vue";
import TableFooter from "@/components/shared/data-table/TableFooter.vue";
import TableHeader from "@/classes/data-table/TableHeader";

import { TableOptions } from "@/classes/data-table/TableOptions";
import { useTable } from "@/composables/table.composable.js";

import { eappStatusFilterOptions, productTypes } from "@/data/filter-options";
import { BEST_TIME_TO_CALL_OPTIONS } from "@/models/ElectronicApplication";

import { parseErrorMessage, currencyFormat, modeFormat } from "@/util/helpers";

import { storeToRefs } from "pinia";
import {
  emailElectronicApplications,
  getElectronicApplications
} from "@/api/electronic-application.service";
import { computed } from "vue";

import { useUserStore } from "@/stores/user";
import { useTableStore } from "@/stores/table";
import { useSnackbarStore } from "@/stores/snackbar";
import { useDialogStore } from "@/stores/dialog";
import { mdiCurrencyUsd, mdiFileExport, mdiRefresh } from "@mdi/js";
import yesNoOptions from "@/data/yes-no-options";
import { useDisplay } from "vuetify/lib/framework.mjs";

const { smAndDown, mdAndUp } = useDisplay();
const user = useUserStore();
const { electronicApplicationsTable } = storeToRefs(useTableStore());
const snackbar = useSnackbarStore();
const dialog = useDialogStore();

function getHeaders() {
  const headers = [
    new TableHeader({
      text: "Insured",
      value: "insuredName",
      map: "insuredName",
      sortFilterMap: "electronic_applications.name",
      ...TableHeader.IS_STRING_FILTER_TYPE,
      ...TableHeader.IS_FILTERABLE
    }),
    new TableHeader({
      text: "Agent",
      value: "agentName",
      map: "agentName",
      sortFilterMap: [{ key: "agent_id", value: "id" }],
      sortKey: "owner_name",
      ...TableHeader.IS_AGENT_SEARCH_FILTER_TYPE,
      ...TableHeader.IS_FILTERABLE,
      ...TableHeader.IS_QUERY_FILTER
    }),
    new TableHeader({
      text: "Status",
      value: "status",
      map: "status",
      sortFilterMap: "electronic_applications.status",
      selectableOptions: eappStatusFilterOptions,
      ...TableHeader.IS_MULTI_SELECT_FILTER_TYPE,
      ...TableHeader.IS_FILTERABLE
    }),
    new TableHeader({
      text: "Website",
      value: "website",
      map: "website",
      sortFilterMap: "approved_domains.domain",
      ...TableHeader.IS_FILTERABLE,
      ...TableHeader.IS_STRING_FILTER_TYPE
    }),
    new TableHeader({
      text: "Product",
      value: "productName",
      map: "productName",
      sortFilterMap: [{ key: "product_id", value: "id" }],
      ...TableHeader.IS_PRODUCT_SEARCH_FILTER_TYPE,
      ...TableHeader.IS_FILTERABLE,
      ...TableHeader.IS_QUERY_FILTER
    }),
    new TableHeader({
      text: "Created",
      value: "created",
      map: "created",
      sortFilterMap: "electronic_applications.created_at",
      ...TableHeader.IS_DATE_FILTER_TYPE,
      ...TableHeader.IS_SORTABLE,
      ...TableHeader.IS_FILTERABLE
    }),
    new TableHeader({
      text: "Completed",
      value: "completed",
      map: "completed",
      sortFilterMap: "completed_at",
      ...TableHeader.IS_ADDITIONAL,
      ...TableHeader.IS_QUERY_FILTER,
      ...TableHeader.IS_DATE_FILTER_TYPE,
      ...TableHeader.IS_SORTABLE,
      ...TableHeader.IS_FILTERABLE
    }),
    new TableHeader({
      text: "Premium",
      value: "premium",
      map: "premium",
      width: '120px',
      ...TableHeader.IS_FILTERABLE,
      ...TableHeader.IS_NUMBER_RANGE_TYPE,
      ...TableHeader.IS_QUERY_FILTER,
      ...TableHeader.IS_CURRENCY
    }),
    new TableHeader({
      text: "Carrier",
      value: "carrierName",
      map: "carrierName",
      sortFilterMap: [{ key: "carrier_id", value: "id" }],
      sortKey: "carrier",
      ...TableHeader.IS_CARRIER_SEARCH_FILTER_TYPE,
      ...TableHeader.IS_FILTERABLE,
      ...TableHeader.IS_QUERY_FILTER
    })
  ];
  if (user.isGroupTwoPlus) {
    headers.unshift(
      new TableHeader({
        text: "View",
        value: "view",
        map: "view",
        selectableOptions: [
          { title: "All eApps", value: "all" },
          { title: "My eApps", value: "my" }
        ],
        sortFilterMap: "view",
        filterOrder: 1,
        ...TableHeader.IS_SELECT_FILTER_TYPE,
        ...TableHeader.IS_FILTERABLE,
        ...TableHeader.IS_ADDITIONAL,
        ...TableHeader.IS_QUERY_FILTER,
        ...TableHeader.IS_MANDATORY
      })
    );
    headers.push(
      new TableHeader({
        text: "Manager",
        value: "marketingManager",
        map: "marketingManager",
        sortFilterMap: [
          { key: "marketing_manager_id", value: "marketing_manager_id" }
        ],
        ...TableHeader.IS_MARKETING_MANAGER_SEARCH_FILTER_TYPE,
        ...TableHeader.IS_FILTERABLE,
        ...TableHeader.IS_QUERY_FILTER
      }),
      new TableHeader({
        text: "Pricing Plan",
        value: "pricingPlan",
        sortFilterMap: [{ key: "pricing_plan_id", value: "id" }],
        ...TableHeader.IS_PRICING_PLAN_SEARCH_TYPE,
        ...TableHeader.IS_FILTERABLE,
        ...TableHeader.IS_QUERY_FILTER,
        ...TableHeader.IS_ADDITIONAL
      })
    );
  }

  headers.push(
    new TableHeader({
      text: "Shared With",
      value: "additionalAdvisor",
      map: "additional_advisor",
      sortFilterMap: [
        { key: "shared_with_id", value: "id" },
        { key: "shared_with_type", value: "type" }
      ],
      ...TableHeader.IS_ADVISOR_SEARCH_FILTER_TYPE,
      ...TableHeader.IS_FILTERABLE,
      ...TableHeader.IS_ADDITIONAL,
      ...TableHeader.IS_QUERY_FILTER
    }),
    new TableHeader({
      text: "Product Category",
      value: "productCategory",
      map: "productCategory",
      sortFilterMap: "categories",
      selectableOptions: productTypes,
      ...TableHeader.IS_MULTI_SELECT_FILTER_TYPE,
      ...TableHeader.IS_QUERY_FILTER,
      ...TableHeader.IS_ADDITIONAL,
      ...TableHeader.IS_FILTERABLE
    }),
    new TableHeader({
      text: "Death Benefit",
      value: "death_benefit",
      map: "death_benefit",
      ...TableHeader.IS_ADDITIONAL,
      ...TableHeader.IS_FILTERABLE,
      ...TableHeader.IS_NUMBER_RANGE_TYPE,
      ...TableHeader.IS_QUERY_FILTER,
      ...TableHeader.IS_CURRENCY
    }),
    new TableHeader({
      text: "Custom Answer",
      value: "customAnswer",
      map: "customAnswer",
      sortFilterMap: "custom_answers",
      ...TableHeader.IS_STRING_FILTER_TYPE,
      ...TableHeader.IS_QUERY_FILTER,
      ...TableHeader.IS_ADDITIONAL,
      ...TableHeader.IS_FILTERABLE
    }),
    new TableHeader({
      text: "Best Time to Call",
      value: "bestTimeToCall",
      map: "bestTimeToCall",
      sortFilterMap: "best_time_to_call",
      selectableOptions: BEST_TIME_TO_CALL_OPTIONS,
      ...TableHeader.IS_SELECT_FILTER_TYPE,
      ...TableHeader.IS_QUERY_FILTER,
      ...TableHeader.IS_ADDITIONAL,
      ...TableHeader.IS_FILTERABLE
    }),
    new TableHeader({
      text: "Is Referred",
      value: "refer",
      map: "refer",
      sortFilterMap: "refer",
      ...TableHeader.IS_CHECKBOX_TYPE,
      ...TableHeader.IS_QUERY_FILTER,
      ...TableHeader.IS_ADDITIONAL,
      ...TableHeader.IS_FILTERABLE
    }),
    new TableHeader({
      text: "Was Contacted",
      value: "contacted",
      map: "contacted",
      sortFilterMap: "contacted",
      ...TableHeader.IS_CHECKBOX_TYPE,
      ...TableHeader.IS_QUERY_FILTER,
      ...TableHeader.IS_ADDITIONAL,
      ...TableHeader.IS_FILTERABLE
    }),
    new TableHeader({
      text: "Is Locked",
      value: "locked",
      map: "locked",
      sortFilterMap: "locked",
      ...TableHeader.IS_CHECKBOX_TYPE,
      ...TableHeader.IS_QUERY_FILTER,
      ...TableHeader.IS_ADDITIONAL,
      ...TableHeader.IS_FILTERABLE
    }),
    new TableHeader({
      text: "Exact Phone Number",
      value: "exactPhoneNumber",
      sortFilterMap: "phone_mobile",
      ...TableHeader.IS_PHONE_TYPE,
      ...TableHeader.IS_ADDITIONAL,
      ...TableHeader.IS_FILTERABLE,
      ...TableHeader.IS_QUERY_FILTER
    }),
    new TableHeader({
      text: "Exact Email",
      value: "exactEmail",
      sortFilterMap: "email",
      ...TableHeader.IS_STRING_FILTER_TYPE,
      ...TableHeader.IS_ADDITIONAL,
      ...TableHeader.IS_FILTERABLE,
      ...TableHeader.IS_QUERY_FILTER
    }),
    new TableHeader({
      text: "Has Email",
      value: "hasEmail",
      sortFilterMap: "has_email",
      selectableOptions: yesNoOptions,
      ...TableHeader.IS_SELECT_FILTER_TYPE,
      ...TableHeader.IS_ADDITIONAL,
      ...TableHeader.IS_FILTERABLE,
      ...TableHeader.IS_QUERY_FILTER,
      ...TableHeader.SEND_FALSE
    })
  );
  return headers;
}

function getLoadInFilter() {
  const headers = getHeaders();
  let filter = {};
  if (user.isGroupThreePlus) {
    filter.view = "all";
  } else if (user.isGroupTwoPlus) {
    filter.view = "my";
  }
  if (electronicApplicationsTable.value.filter) {
    Object.keys(electronicApplicationsTable.value.filter).forEach(key => {
      if (!headers.find(header => header.value === key)) return;
      filter[key] = electronicApplicationsTable.value.filter[key];
    });
  }
  return filter;
}

function getLoadInOptions() {
  let options = TableOptions({ sortBy: [{ key: "created", order: "desc" }] });
  if (Object.keys(electronicApplicationsTable.value.options).length) {
    options = electronicApplicationsTable.value.options;
  }
  return options;
}

const table = useTable({
  emailData: emailElectronicApplications,
  getData: getElectronicApplications,
  headers: getHeaders(),
  filter: getLoadInFilter(),
  options: getLoadInOptions(),
  shouldIncludeCancelToken: true
});

const stats = computed(() => {
  const premium = {
    text: "Average Premium",
    icon: mdiCurrencyUsd,
    type: "loading",
    dataTestid: "stat-average-premium"
  };
  const completion = {
    text: "eApp Completion",
    icon: mdiCurrencyUsd,
    type: "loading",
    dataTestid: "stat-eapp-completion"
  };
  const conversion = {
    text: "Completed eApp Placement Ratio",
    icon: mdiCurrencyUsd,
    type: "loading",
    dataTestid: "stat-eapp-conversion"
  };

  if ("averagePremium" in table.stats.value) {
    premium.value = table.stats.value.averagePremium;
    premium.type = "dollar";
  }

  if ("completion" in table.stats.value) {
    completion.value = table.stats.value.completion * 100;
    completion.type = "percent";
  }

  if ("conversion" in table.stats.value) {
    conversion.value = table.stats.value.conversion * 100;
    conversion.type = "percent";
  }

  return [premium, completion, conversion];
});

async function getData() {
  try {
    const additionalFilter = { stats: "true" };
    if (user.isGroupOne) additionalFilter.view = "my";
    await table.getData(additionalFilter);
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
  }
}

async function emailData() {
  try {
    await table.emailData();
    snackbar.showSuccessSnackbar({
      message: "You will receive a copy of this data in your inbox shortly.",
      timeout: 6000
    });
  } catch (error) {
    snackbar.showErrorSnackbar({
      message: parseErrorMessage(error),
      timeout: -1
    });
  }
}

function premiumText(item) {
  if (!item.premium && item.premium !== 0) return;
  return `${currencyFormat(item.premium, 0)}`;
}

function modeText(item) {
  if (!item.mode) return;
  if (item.mode === 1 && item.payDuration === 1) return "Single Pay";
  return modeFormat(item.mode);
}

function confirmAndEmailData() {
  dialog.showDialog({
    component: "ConfirmationDialog",
    title: "Email Data?",
    subtitle: "Please confirm you would like this data emailed to you.",
    func: emailData
  });
}

function updateOptions(newOptions) {
  if (table.optionsEquivalence(newOptions)) return;
  electronicApplicationsTable.value.options = newOptions;
  getData();
}

function updateFilter(filter) {
  table.filter.value = filter;
  electronicApplicationsTable.value.filter = filter;
  table.resetPage();
  getData();
}
</script>
