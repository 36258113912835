<template>
  <v-card height="100%" width="100%" flat tile color="section">
    <v-card v-if="!noFields" flat tile :color="color" style="min-height: 48px">
      <v-card-title class="pa-1 text-white justify-space-between flex-nowrap flex-row">
        <div v-if="activeField?.id" class="pl-3">
          <mapped-form-view-aql-copy-button
            v-if="isCopiedForm && !isReadonly"
            :form-id="formId"
          />
          {{ isCopiedForm ? "[COPY]" : null }}
          {{ applicationQuestionDisplayText(activeField) }}
        </div>
        <v-spacer />
        <mapped-form-view-question-positional-navigation :form-id="formId" />
      </v-card-title>
    </v-card>
    <v-card
      v-if="copyingFromFormId"
      :color="`${color} darken-1`"
      flat
      tile
      height="30"
    >
      <v-card-subtitle
        v-if="questionsAreLinked"
        data-testid="linked-questions"
        class="text-white text-center py-1"
      >
        Questions Are Linked!
      </v-card-subtitle>
    </v-card>
    <pdf-viewer
      :form-id="formId"
      :can-delete="!isCopiedForm && !readonly && pageCount > 1"
      @delete-page="handlePageDelete"
      @page-count="pageCount = $event"
    >
      <template #pdf-overlay>
        <high-dpi-canvas
          v-if="window.width && allVisible"
          :id="`pdf-overlay-${formId}`"
          :height="window.height"
          :width="window.width"
          :scale="scale"
          @click="handleCanvasClick"
          @redraw="redraw(true)"
          @ready="redraw(true)"
        />
      </template>
      <template #edit-field-overlay>
        <mapped-form-view-aql-pdf-editor
          v-if="activeField?.id"
          :key="currentFieldId"
          :readonly="readonly"
          :all-visible="allVisible"
          :form-id="formId"
          :current-page="currentPage"
          :rectangle-is-visible="rectangleIsVisible"
          :show-all-radio-values="showAllRadioValues"
          @click="handleCanvasClick"
        />
      </template>
      <template #footer-menu>
        <mapped-form-view-aql-pdf-menu
          v-if="activeField?.id"
          v-model:visible="rectangleIsVisible"
          v-model:all-visible="allVisible"
          v-model:all-radio-values-visible="showAllRadioValues"
          :form-id="formId"
          :pdf-type="activeField.pdfFieldType"
        />
      </template>
    </pdf-viewer>
  </v-card>
</template>

<script setup>
import MappedFormViewQuestionPositionalNavigation from "@/components/form-mapping/MappedFormViewQuestionPositionalNavigation.vue";
import MappedFormViewAqlPdfMenu from "@/components/form-mapping/MappedFormViewAqlPdfMenu.vue";
import MappedFormViewAqlCopyButton from "@/components/form-mapping/MappedFormViewAqlCopyButton.vue";
import MappedFormViewAqlPdfEditor from "@/components/form-mapping/MappedFormViewAqlPdfEditor.vue";
import PdfViewer from "@/components/form-mapping/PdfViewer.vue";
import HighDpiCanvas from "@/components/shared/HighDpiCanvas.vue";
import ConfirmationDialog from "@/dialogs/ConfirmationDialog.vue";
import { useMappedFormStore } from "@/stores/mapped-form";
import { storeToRefs } from "pinia";
import { ref,  onMounted, watch, computed, markRaw } from "vue";
import {
  CHECKBOX_OPTION_TYPE,
  CHECKBOX_RADII,
  CHECKBOX_SIDE_LENGTH,
  RADIO_OPTION_RADIUS,
  applicationQuestionDisplayText
} from "@/models/FormMapping";
import {
  clickedWithinBoundingBox,
  drawCheckbox,
  drawCircle,
  drawRectangle
} from "@/util/helpers";
import { useDialogStore } from "@/stores/dialog";
import { useFormMappingView } from "@/stores/form-mapping-view";

const props = defineProps({
  formId: { type: [String, Number], required: true },
  noFields: Boolean
});

const dialog = useDialogStore();
const formStore = useMappedFormStore(props.formId);

const {
  activeField,
  currentFieldId,
  positionallySortedFields,
  currentCoordinateIndex,
  currentPage,
  viewport,
  window,
  scale,
  otherFields,
  otherActiveField,
  pageCount,
  readonly,
  color
} = storeToRefs(formStore);

const { copyingFromFormId, isReadonly } = storeToRefs(useFormMappingView());

const allVisible = ref(true);
const rectangleIsVisible = ref(true);
const showAllRadioValues = ref(true);

const isCopiedForm = computed(() => copyingFromFormId.value === props.formId);

onMounted(() => {
  let page;
  if (activeFieldCoordinates.value.length) {
    const firstPageValue = activeField.value.coordinates.find(
      ({ virtual, page }) => !virtual && page
    );
    page = firstPageValue.page;
  }
  currentPage.value = page || 1;
});

const questionsAreLinked = computed(() => {
  if (!copyingFromFormId.value) return false;
  if (isCopiedForm.value) {
    return activeField.value.id === otherActiveField.value?.referenceField;
  }
  return activeField.value?.referenceField === otherActiveField.value.id;
});

const fieldsOnPage = computed(() => {
  return positionallySortedFields.value.filter(({ coordinates }) => {
    if (!coordinates?.length) return false;
    return coordinates.some(({ page }) => `${page}` === `${currentPage.value}`);
  });
});

const fieldCount = computed(() => positionallySortedFields.value.length);

const activePageRectangles = computed(() => {
  const rectangles = [];
  for (const fieldOnPage of fieldsOnPage.value) {
    if (!fieldOnPage?.coordinates?.length) return;
    fieldOnPage.coordinates.forEach(
      ({ x, y, height, width, isFieldOption, virtual }, coordinateIndex) => {
        if (virtual) return;
        let rectY = viewport.value.height - y;
        if (!isFieldOption) rectY -= height;
        // is copying && !is copied form
        // if the current field is copied, fetch the color based on the copied form's field.by_id[referenceField]
        let color = fieldOnPage.color;

        if (
          otherFields.value.by_id &&
          otherFields.value.by_id[fieldOnPage.referenceField]
        ) {
          color = otherFields.value.by_id[fieldOnPage.referenceField].color;
        }

        rectangles.push({
          x,
          y: rectY,
          width,
          height,
          isFieldOption,
          aql: fieldOnPage,
          color,
          coordinateIndex
        });
      }
    );
  }
  return rectangles;
});

const activeFieldCoordinates = computed(
  () => activeField.value.coordinates || []
);

const activeFieldPage = computed(() => {
  if (!activeFieldCoordinates.value.length) return null;
  const firstPageValue = activeField.value.coordinates.find(
    ({ virtual, page }) => !virtual && page
  );
  return firstPageValue?.page;
});

async function handlePageDelete() {
  await dialog.showDialog({
    component: markRaw(ConfirmationDialog),
    title: "Confirm Page Delete",
    subtitle: "The page will reload once complete",
    func: async () => {
      await formStore.deletePage();
      location.reload();
    }
  });
}

function drawAllBoxesOnPage(ctx) {
  const drawnParents = [];
  activePageRectangles.value.forEach(
    ({ x, aql, isFieldOption, y, width, height, color }) => {
      const isActive = aql.id === activeField.value.id;
      let isActiveParent = false;
      let drawParentText = false;
      if (activeField.value.parentQuestion) {
        isActiveParent = `${aql.id}` === `${activeField.value?.parentQuestion}`;
      }
      if (isActiveParent && !drawnParents.includes(aql.id)) {
        drawParentText = true;
        drawnParents.push(aql.id);
      }
      if (isActive) return;
      const isUnmapped = !aql.applicationQuestion?.id;

      let func = (fillColor, strokeColor, text) => {
        if (!aql.obscureBackground) {
          return drawRectangle(ctx, {
            x,
            y,
            width,
            height,
            text,
            fillColor,
            strokeColor,
            comb: aql.comb
          });
        }
        drawRectangle(ctx, {
          x,
          y,
          width,
          height,
          text,
          fillColor,
          strokeColor
        });
        drawRectangle(ctx, {
          x: x + 4,
          y: y + 4,
          width: width - 8,
          height: height - 8,
          fillColor: "#ffffff",
          strokeColor: "#ffffff"
        });
        drawRectangle(ctx, {
          x,
          y,
          width,
          height,
          text,
          fillColor: "rgba(0,0,0,0)",
          strokeColor,
          comb: aql.comb
        });
      };

      if (aql.pdfFieldType === CHECKBOX_OPTION_TYPE) {
        func = (fillColor, strokeColor, text) =>
          drawCheckbox(ctx, {
            sideLength: CHECKBOX_SIDE_LENGTH,
            radii: CHECKBOX_RADII,
            x,
            y,
            strokeColor,
            fillColor,
            text
          });
      } else if (isFieldOption) {
        func = (fillColor, strokeColor, text) =>
          drawCircle(ctx, {
            radius: RADIO_OPTION_RADIUS,
            x,
            y,
            strokeColor,
            fillColor,
            text
          });
      }

      if (isUnmapped) {
        func("red", "red", "Unmapped");
      } else if (isActiveParent) {
        func(null, color, drawParentText ? "Parent" : null);
      } else {
        func(color, color);
      }
    }
  );
}

function getCurrentCanvas() {
  return document.getElementById(`pdf-overlay-${props.formId}`);
}

function handleCanvasClick(e) {
  const el = getCurrentCanvas();
  const yRatio = viewport.value.height / el.clientHeight;
  const xRatio = viewport.value.width / el.clientWidth;
  const mouseY = yRatio * e.offsetY;
  const mouseX = xRatio * e.offsetX;
  const index = activePageRectangles.value.findIndex(rect =>
    clickedWithinBoundingBox({
      mouseX,
      mouseY,
      rectX: rect.x,
      rectY: rect.y,
      rectWidth: rect.width,
      rectHeight: rect.height,
      isCircle: rect.isFieldOption,
      radius: RADIO_OPTION_RADIUS
    })
  );
  if (index === -1) return;
  formStore.changeActiveField(activePageRectangles.value[index].aql.id);
  currentCoordinateIndex.value =
    activePageRectangles.value[index].coordinateIndex;
  redraw();
}

async function redraw(draw = true) {
  const el = getCurrentCanvas();
  if (!el) return;

  const ctx = el.getContext("2d");
  ctx.clearRect(0, 0, el.width, el.height);
  if (draw) drawAllBoxesOnPage(ctx);
}

watch([currentPage, currentFieldId, fieldCount, allVisible], redraw);

watch(activeFieldPage, () => {
  if (activeFieldPage.value === null || !activeFieldCoordinates.value.length)
    return;
  currentPage.value = activeFieldPage.value || 1;
});

watch(
  () => activeField.value?.id,
  () => {
    if (!activeFieldCoordinates.value.length) return;
    const firstPageValue = activeField.value.coordinates.find(
      ({ virtual, page }) => !virtual && page
    );
    if (!firstPageValue) return;
    currentPage.value = firstPageValue.page || 1;
  }
);
</script>
