import { defineStore } from "pinia";
import {
  Carrier,
  RAW_PERSONNEL_PREFIX,
  LINE,
  RAW_PHONE_NUMBER,
  ADDRESS
} from "@/models/Carrier";
import {
  getCarrierData,
  updateCarrier,
  updateCarrierAddress,
  uploadCarrierDocument
} from "@/api/carrier.service";

export const useCarrierView = defineStore("carrier", {
  state: () => Carrier(),
  actions: {
    async fetchCarrierData(id) {
      this.$reset();
      const carrier = await getCarrierData(id);
      this.$patch(carrier);
    },
    updateCarrier(body) {
      return updateCarrier(this.id, body);
    },
    updateDetails() {
      return this.updateCarrier({ details: this.details });
    },
    updateAddressee() {
      return this.updateCarrier({ addressee: this.addressee });
    },
    updateQuotingStates() {
      return this.updateCarrier({
        states: this.quotingStates.map(val => ({ name: val }))
      });
    },
    updateSupportsInstantAps() {
      return this.updateCarrier({
        instant_aps: this.supportsInstantAps
      });
    },
    updateAutoSubmitContracting() {
      return this.updateCarrier({
        auto_submit_contracting: this.autoSubmitContracting
      });
    },
    updateHoldForAppointment() {
      return this.updateCarrier({
        hold_for_appointment: this.holdForAppointment
      });
    },
    updateStrifeApproved() {
      return this.updateCarrier({ strife_approved: this.strifeApproved });
    },
    updateRequiresWetSignature() {
      return this.updateCarrier({
        requires_wet_signature: this.requiresWetSignature
      });
    },
    updateEmailUnderwriter() {
      return this.updateCarrier({ email_underwriter: this.emailUnderwriter });
    },
    updateEmailEo() {
      return this.updateCarrier({ email_eo: this.emailEo });
    },
    updateStrifeDisclosure() {
      return this.updateCarrier({ strife_disclosure: this.strifeDisclosure });
    },
    updateEftCommissionSchedule() {
      return this.updateCarrier({
        eft_commission_schedule: this.eftCommissionSchedule
      });
    },
    updateCheckCommissionSchedule() {
      return this.updateCarrier({
        check_commission_schedule: this.checkCommissionSchedule
      });
    },
    updateDefaultAppointmentManager() {
      return this.updateCarrier({
        default_appointment_manager_id: this.defaultAppointmentManager?.id
      });
    },
    updateParamedsCode() {
      return this.updateCarrier({ parameds_code: this.paramedsCode });
    },
    updateParamedsAccountNumber() {
      return this.updateCarrier({
        parameds_acct_num: +this.paramedsAccountNumber
      });
    },
    updateNaic() {
      return this.updateCarrier({ naic: this.naic });
    },
    updateAmBestRating() {
      return this.updateCarrier({ am_best_rating: this.amBestRating });
    },
    updateWebsites() {
      return this.updateCarrier({
        websites: {
          main: this.websitesByLine.main,
          [LINE.ANNUITY]: this.websitesByLine[LINE.ANNUITY],
          [LINE.DISABILITY]: this.websitesByLine[LINE.DISABILITY],
          [LINE.LIFE]: this.websitesByLine[LINE.LIFE],
          [LINE.LTC]: this.websitesByLine[LINE.LTC]
        }
      });
    },
    updateESignature() {
      return this.updateCarrier({
        e_signature: {
          [LINE.ANNUITY]: this.eSignatureByLine[LINE.ANNUITY],
          [LINE.DISABILITY]: this.eSignatureByLine[LINE.DISABILITY],
          [LINE.LIFE]: this.eSignatureByLine[LINE.LIFE],
          [LINE.LTC]: this.eSignatureByLine[LINE.LTC]
        }
      });
    },
    updateRequiresOriginalApplication() {
      const yesOrNo = v => (v == "Yes" ? "Yes" : "No");
      return this.updateCarrier({
        requires_original_application: {
          [LINE.ANNUITY]: yesOrNo(
            this.requiresOriginalApplicationByLine[LINE.ANNUITY]
          ),
          [LINE.DISABILITY]: yesOrNo(
            this.requiresOriginalApplicationByLine[LINE.DISABILITY]
          ),
          [LINE.LIFE]: yesOrNo(
            this.requiresOriginalApplicationByLine[LINE.LIFE]
          ),
          [LINE.LTC]: yesOrNo(this.requiresOriginalApplicationByLine[LINE.LTC])
        }
      });
    },
    updateEDelivery() {
      return this.updateCarrier({
        e_delivery: {
          [LINE.ANNUITY]: this.eDeliveryByLine[LINE.ANNUITY],
          [LINE.DISABILITY]: this.eDeliveryByLine[LINE.DISABILITY],
          [LINE.LIFE]: this.eDeliveryByLine[LINE.LIFE],
          [LINE.LTC]: this.eDeliveryByLine[LINE.LTC]
        }
      });
    },
    updateGACanOrderAps() {
      return this.updateCarrier({
        ga_can_order_aps: {
          [LINE.ANNUITY]: this.gaCanOrderApsByLine[LINE.ANNUITY],
          [LINE.DISABILITY]: this.gaCanOrderApsByLine[LINE.DISABILITY],
          [LINE.LIFE]: this.gaCanOrderApsByLine[LINE.LIFE],
          [LINE.LTC]: this.gaCanOrderApsByLine[LINE.LTC]
        }
      });
    },

    updateMultipleAppointments() {
      return this.updateCarrier({
        multiple_appointments: {
          [LINE.ANNUITY]: this.multipleAppointmentsByLine[LINE.ANNUITY],
          [LINE.DISABILITY]: this.multipleAppointmentsByLine[LINE.DISABILITY],
          [LINE.LIFE]: this.multipleAppointmentsByLine[LINE.LIFE],
          [LINE.LTC]: this.multipleAppointmentsByLine[LINE.LTC]
        }
      });
    },
    updateAnnualization() {
      const boolToS = v => (v === "true" ? "true" : "false");

      return this.updateCarrier({
        annualization: {
          [LINE.ANNUITY]: boolToS(this.annualizationByLine[LINE.ANNUITY]),
          [LINE.DISABILITY]: boolToS(this.annualizationByLine[LINE.DISABILITY]),
          [LINE.LIFE]: boolToS(this.annualizationByLine[LINE.LIFE]),
          [LINE.LTC]: boolToS(this.annualizationByLine[LINE.LTC])
        }
      });
    },
    updateAnnualizationDetails() {
      return this.updateCarrier({
        annualization_details: {
          [LINE.ANNUITY]: this.annualizationDetailsByLine[LINE.ANNUITY],
          [LINE.DISABILITY]: this.annualizationDetailsByLine[LINE.DISABILITY],
          [LINE.LIFE]: this.annualizationDetailsByLine[LINE.LIFE],
          [LINE.LTC]: this.annualizationDetailsByLine[LINE.LTC]
        }
      });
    },
    updatePaysFullOverride() {
      return this.updateCarrier({
        pays_full_override: {
          [LINE.ANNUITY]: Boolean(this.paysFullOverrideByLine[LINE.ANNUITY]),
          [LINE.DISABILITY]: Boolean(
            this.paysFullOverrideByLine[LINE.DISABILITY]
          ),
          [LINE.LIFE]: Boolean(this.paysFullOverrideByLine[LINE.LIFE]),
          [LINE.LTC]: Boolean(this.paysFullOverrideByLine[LINE.LTC])
        }
      });
    },
    updatePaymentSchedule() {
      return this.updateCarrier({
        payment_schedule: {
          [LINE.ANNUITY]: this.paymentScheduleByLine[LINE.ANNUITY],
          [LINE.DISABILITY]: this.paymentScheduleByLine[LINE.DISABILITY],
          [LINE.LIFE]: this.paymentScheduleByLine[LINE.LIFE],
          [LINE.LTC]: this.paymentScheduleByLine[LINE.LTC]
        }
      });
    },
    updateSendContracting() {
      return this.updateCarrier({
        send_contracting: {
          [LINE.ANNUITY]: this.sendContractingByLine[LINE.ANNUITY],
          [LINE.DISABILITY]: this.sendContractingByLine[LINE.DISABILITY],
          [LINE.LIFE]: this.sendContractingByLine[LINE.LIFE],
          [LINE.LTC]: this.sendContractingByLine[LINE.LTC]
        }
      });
    },
    updatePreappointmentStates() {
      return this.updateCarrier({
        preappointment_states: {
          [LINE.ANNUITY]: this.preappointmentStatesByLine[LINE.ANNUITY],
          [LINE.DISABILITY]: this.preappointmentStatesByLine[LINE.DISABILITY],
          [LINE.LIFE]: this.preappointmentStatesByLine[LINE.LIFE],
          [LINE.LTC]: this.preappointmentStatesByLine[LINE.LTC]
        }
      });
    },
    updateAutoSubmit() {
      const boolToS = v => (v ? "true" : "false");
      return this.updateCarrier({
        auto_submit: {
          [LINE.ANNUITY]: boolToS(this.autoSubmitByLine[LINE.ANNUITY]),
          [LINE.DISABILITY]: boolToS(this.autoSubmitByLine[LINE.DISABILITY]),
          [LINE.LIFE]: boolToS(this.autoSubmitByLine[LINE.LIFE]),
          [LINE.LTC]: boolToS(this.autoSubmitByLine[LINE.LTC])
        }
      });
    },
    updateEmails() {
      return this.updateCarrier({
        emails: {
          [LINE.ANNUITY]: {
            licensing: this.emailsByLine[LINE.ANNUITY].licensing,
            new_business: this.emailsByLine[LINE.ANNUITY].newBusiness,
            new_application: this.emailsByLine[LINE.ANNUITY].newApplication,
            quick_quote: this.emailsByLine[LINE.ANNUITY].quickQuote
          },
          [LINE.LTC]: {
            licensing: this.emailsByLine[LINE.LTC].licensing,
            new_business: this.emailsByLine[LINE.LTC].newBusiness,
            new_application: this.emailsByLine[LINE.LTC].newApplication,
            quick_quote: this.emailsByLine[LINE.LTC].quickQuote
          },
          [LINE.DISABILITY]: {
            licensing: this.emailsByLine[LINE.DISABILITY].licensing,
            new_business: this.emailsByLine[LINE.DISABILITY].newBusiness,
            new_application: this.emailsByLine[LINE.DISABILITY].newApplication,
            quick_quote: this.emailsByLine[LINE.DISABILITY].quickQuote
          },
          [LINE.LIFE]: {
            licensing: this.emailsByLine[LINE.LIFE].licensing,
            new_business: this.emailsByLine[LINE.LIFE].newBusiness,
            new_application: this.emailsByLine[LINE.LIFE].newApplication,
            quick_quote: this.emailsByLine[LINE.LIFE].quickQuote
          }
        }
      });
    },
    updateTrainingByLine(type) {
      if (type !== LINE.ANNUITY) throw new Error("Invalid line type");
      return this.updateCarrier({
        product_training: this.trainingByLine[type]
      });
    },
    updateReg187TrainingByLine(type) {
      if (![LINE.LIFE, LINE.ANNUITY].includes(type))
        throw new Error("Invalid line type");

      return this.updateCarrier({
        reg_187: {
          [LINE.LIFE]: this.reg187TrainingByLine[LINE.LIFE],
          [LINE.ANNUITY]: this.reg187TrainingByLine[LINE.ANNUITY]
        }
      });
    },
    updateNewBusinessAddressByLine(type) {
      return updateCarrierAddress(this.id, {
        address_type: `${type},${ADDRESS.NEW_BUSINESS}`,
        city: this.newBusinessAddressByLine[type].city,
        state: this.newBusinessAddressByLine[type].state,
        street_address: this.newBusinessAddressByLine[type].street_address,
        zip: this.newBusinessAddressByLine[type].zip
      });
    },
    updateDetailsByLine(type) {
      return this.updateCarrier({
        [`${type}_details`]: this.detailsByLine[type]
      });
    },
    updateNewApplicationPersonnelByLine(type) {
      return this.updateCarrier({
        [`${RAW_PERSONNEL_PREFIX.NEW_APP}_${type}_id`]:
          this.newAppPersonnelByLine[type]?.id || null
      });
    },
    updateNewBusinessPersonnelByLine(type) {
      return this.updateCarrier({
        [`${RAW_PERSONNEL_PREFIX.NEW_BUSINESS}_${type}_id`]:
          this.newBusinessPersonnelByLine[type]?.id || null
      });
    },
    updateCustomerServicePersonnelByLine(type) {
      return this.updateCarrier({
        [`${RAW_PERSONNEL_PREFIX.CUSTOMER_SERVICE}_${type}_id`]:
          this.customerServicePersonnelByLine[type]?.id || null
      });
    },
    updateLicensingPersonnelByLine(type) {
      return this.updateCarrier({
        [`${RAW_PERSONNEL_PREFIX.LICENSING}_${type}_id`]:
          this.licensingPersonnelByLine[type]?.id || null
      });
    },
    updatePhoneNumberByLine(type) {
      const phoneKey = `${type}_phone`;
      const phoneNumbers = {
        [RAW_PHONE_NUMBER.CUSTOMER_SERVICE]:
          this.customerServicePhoneByLine[type] || "",
        [RAW_PHONE_NUMBER.LICENSING]: this.licensingPhoneByLine[type] || "",
        [RAW_PHONE_NUMBER.LICENSING_FAX]: this.licensingFaxByLine[type] || "",
        [RAW_PHONE_NUMBER.NEW_BUSINESS]:
          this.newBusinessPhoneByLine[type] || "",
        [RAW_PHONE_NUMBER.NEW_BUSINESS_FAX]:
          this.newBusinessFaxByLine[type] || "",
        [RAW_PHONE_NUMBER.SALES_DESK]: this.salesDeskPhoneByLine[type] || ""
      };

      return this.updateCarrier({ [phoneKey]: JSON.stringify(phoneNumbers) });
    },
    uploadDocument({ category, file }) {
      return uploadCarrierDocument({
        id: this.id,
        category,
        file
      });
    }
  }
});
