<template>
  <task-viewer
    :can-change-status="user.isGroupTwoPlus"
    :is-complete="complete"
    :pusher-id="pusherId"
    :documents="documents"
    :description="description"
    :messages="visibleMessages"
    :loading-status="loadingStatus"
    @new-note="addNewNote"
    @submit="sendNote"
    @delete-message="deleteNote"
  >
    <template #header>
      <v-row class="ma-0" align="center">
        <slot name="back-button" />
        <v-tabs
          v-if="showCarrierChat"
          v-model="chatTab"
          show-arrows
          background-color="transparent"
          color="primary"
        >
          <v-tab data-testid="advisor-task-notes" class="text-none">
            Advisor Task Notes
          </v-tab>
          <v-tab data-testid="carrier-task-notes" class="text-none">
            Carrier Task Notes
          </v-tab>
        </v-tabs>
        <h3
          v-else
          class="font-weight-regular pt-3 pl-3"
          style="font-size: 1.5em"
        >
          Task Details
        </h3>
      </v-row>
    </template>
    <template #task-header>
      <v-row class="ma-0 text-body-2" align="center" data-testid="active-task-header">
        {{ headerText }}
        <div v-if="user.isGroupTwoPlus">
          <app-button
            data-testid="edit-task"
            color="accent"
            variant="text"
            density="comfortable"
            :icon="mdiPencil"
            @click="editTask"
          />
          <app-button
            data-testid="delete-task"
            color="error"
            variant="text"
            density="comfortable"
            :icon="mdiDelete"
            @click="confirmAndDeleteTask"
          />
        </div>
      </v-row>
    </template>
    <template v-if="showEmail || showPhoneCall" #extra-actions>
      <div class="my-3">
        <div v-if="showEmail" class="checkbox-width inline-block">
          <checkbox-field
            v-model="sendEmail"
            label="Send Email"
            class="mt-0 mb-1"
            data-testid="email-message"
            hide-details
          />
        </div>
        <div v-if="showPhoneCall" class="checkbox-width inline-block ml-3">
          <checkbox-field
            v-model="phoneCall"
            label="Phone Call"
            class="mt-0 mb-1"
            data-testid="phone-call-message"
            hide-details
          />
        </div>
      </div>
    </template>
  </task-viewer>
</template>

<script setup>
import ConfirmationDialog from "@/dialogs/ConfirmationDialog.vue";
import TaskViewer from "@/components/shared/tasks/TaskViewer.vue";
import CaseViewTaskUpdateDialog from "@/components/cases/case-view/CaseViewTaskUpdateDialog.vue";

import { parseErrorMessage, timestampFormatter } from "@/util/helpers";
import { useCaseViewStore } from "@/stores/case-view";
import { useCaseViewActiveTaskStore } from "@/stores/case-view-active-task";
import { useUserStore } from "@/stores/user";
import { storeToRefs } from "pinia";
import { computed, markRaw, ref, toRefs, watch } from "vue";
import { useDialogStore } from "@/stores/dialog";
import { CASE_TASK_STATUS } from "@/models/Case";
import { Note } from "@/models/Note";
import { useSnackbarStore } from "@/stores/snackbar";
import { mdiPencil, mdiDelete } from "@mdi/js";

const props = defineProps({
  loading: Boolean
});

const { loading } = toRefs(props);

const user = useUserStore();
const caseViewStore = useCaseViewStore();
const activeTaskStore = useCaseViewActiveTaskStore();
const dialog = useDialogStore();
const snackbar = useSnackbarStore();

const {
  complete,
  description,
  documents,
  pusherId,
  assigned,
  createdAt,
  followUpDate,
  notes,
  ownableName,
  isPhysicianStatement,
  isExam,
  activeTaskKey,
  orderNumber,
  vendor,
  hidden
} = storeToRefs(activeTaskStore);

const { room, assignOptions, LINE } = storeToRefs(caseViewStore);

const chatTab = ref(0);
const phoneCall = ref(false);
const sendEmail = ref(true);
const loadingStatus = ref("");

watch(
  loading,
  v => {
    loadingStatus.value = v ? "loading" : "";
  },
  { immediate: true }
);

const hasCarrierPersonnel = computed(() =>
  room.value.some(v => v.type === "Personnel")
);

const carrierMessages = computed(() =>
  notes.value.filter(v => v.carrierAccess)
);
const advisorMessages = computed(() =>
  notes.value.filter(v => v.advisorAccess)
);

const viewingAdvisorMessages = computed(() => chatTab.value === 0);
const viewingCarrierMessages = computed(() => chatTab.value === 1);

const showEmail = computed(() => !hidden.value);
const showPhoneCall = computed(
  () => user.isGroupTwoPlus && !viewingCarrierMessages.value && !hidden.value
);
watch(showEmail, v => (sendEmail.value = v));
watch(showPhoneCall, () => (phoneCall.value = false));

const visibleMessages = computed(() => {
  if (!showCarrierChat.value) return advisorMessages.value;

  if (viewingAdvisorMessages.value) return advisorMessages.value;
  else if (viewingCarrierMessages.value) return carrierMessages.value;
  return [];
});

const showCarrierChat = computed(() => {
  return (
    user.isGroupTwoPlus &&
    !LINE.value.INFORMAL &&
    (hasCarrierPersonnel.value || carrierMessages.value)
  );
});

const headerText = computed(() => {
  const text = [
    `Created by ${ownableName.value} on ${timestampFormatter(createdAt.value)}.`
  ];

  if (followUpDate.value) {
    text.push(`Follow up on ${timestampFormatter(followUpDate.value)}.`);
  }

  text.push(`Assigned to ${assigned.value.name}.`);

  return text.join(" ");
});

function confirmAndDeleteTask() {
  dialog.showDialog({
    component: markRaw(ConfirmationDialog),
    title: "Are you sure that you want to delete this Task?",
    subtitle: "This cannot be undone.",
    func: async () => {
      await activeTaskStore.deleteTask();
      caseViewStore.deleteTask(activeTaskKey.value);
      activeTaskStore.$reset();
    }
  });
}

async function editTask() {
  const assignedToOptions = [...assignOptions.value];
  if (vendor.value?.id) {
    const vendorIncluded = assignedToOptions.some(
      v => v.id === vendor.value?.id && v.type === "Vendor"
    );
    if (!vendorIncluded) {
      assignedToOptions.push({
        id: vendor.value.id,
        type: "Vendor",
        name: vendor.value.name
      });
    }
  }

  const result = await dialog.showDialog({
    component: markRaw(CaseViewTaskUpdateDialog),
    isPhysicianStatement: isPhysicianStatement.value,
    isExam: isExam.value,
    assignedToOptions,
    assignedTo: assigned.value,
    followUpDate: followUpDate.value,
    updateFn: activeTaskStore.updateTask,
    orderNumber: orderNumber.value
  });
  if (!result?.task) return;
  caseViewStore.syncTask(activeTaskKey.value);
}

async function sendNote({
  complete: markedAsComplete,
  files,
  message,
  callback
}) {
  const currentIsComplete =
    activeTaskStore.status === CASE_TASK_STATUS.COMPLETE;

  const isStatusChange =
    currentIsComplete !== markedAsComplete &&
    [true, false].includes(markedAsComplete);

  loadingStatus.value = isStatusChange ? "status-change" : "submit";

  try {
    if (files?.length || message) {
      const noteToCreate = Note({
        documents: files,
        note: message,
        advisorAccess: viewingAdvisorMessages.value,
        carrierAccess: viewingCarrierMessages.value,
        email:
          viewingAdvisorMessages.value && sendEmail.value && showEmail.value,
        carrierEmail:
          viewingCarrierMessages.value && sendEmail.value && showEmail.value,
        phoneCall: phoneCall.value && showPhoneCall.value,
        notableId: activeTaskStore.config.notableId,
        notableType: activeTaskStore.config.notableType
      });

      await activeTaskStore.createNote(noteToCreate);
      phoneCall.value = false;
      sendEmail.value = true;
    }

    if (isStatusChange) {
      await activeTaskStore.updateStatus(markedAsComplete);
    }

    caseViewStore.syncTask(activeTaskKey.value);
    if (callback) callback();
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
  }

  loadingStatus.value = "";
}

async function deleteNote({ id }) {
  dialog.showDialog({
    component: markRaw(ConfirmationDialog),
    title: "Are you sure that you want to delete this note?",
    subtitle: "This cannot be undone.",
    func: async () => {
      await activeTaskStore.deleteNote(id);
    }
  });
}

function addNewNote(note) {
  activeTaskStore.addNewNote(note);
}
</script>
