<template>
  <v-card>
    <v-card-title>
      {{
        creating
          ? `Give an Advisor Access to ${advisorName}`
          : `Control ${connectionToEdit.connectedAdvisorName}'s Access`
      }}
    </v-card-title>
    <v-card-text>
      <v-row dense>
        <v-col v-if="creating" cols="12">
          <advisor-search
            v-model="advisor"
            data-testid="speicify-advisor"
            autofocus
            :success="advisorValidation.success"
            :error-messages="advisorValidation.errorMessages"
            :clearable="false"
          >
            <template v-if="canAddSelf && !currentAdvisorIsSelf" #message>
              <a @click="makeUserTheAdvisor">
                <v-icon color="primary" size="12" :icon="mdiPlus" /> Add
                {{ user.loginable.name }}
              </a>
            </template>
          </advisor-search>
        </v-col>
        <v-col cols="12">
          <v-sheet color="section" class="pa-3 rounded">
            <connection-settings
              v-model:appointments="connection.appointments"
              v-model:appointments-emails="connection.appointmentsEmails"
              v-model:cases="connection.cases"
              v-model:cases-emails="connection.casesEmails"
              v-model:contract-parties="connection.contractParties"
              v-model:contract-parties-emails="connection.contractPartiesEmails"
              v-model:eapps="connection.electronicApplications"
              v-model:eapps-emails="connection.electronicApplicationsEmails"
              v-model:payments="connection.payments"
              v-model:payments-emails="connection.paymentsEmails"
              v-model:quick-quotes="connection.quickQuotes"
              v-model:quick-quotes-emails="connection.quickQuotesEmails"
              v-model:quotes="connection.quotes"
              v-model:quotes-emails="connection.quotesEmails"
              :connection-name="connectionName"
              :advisor-name="advisorName"
            />
          </v-sheet>
        </v-col>
        <v-col cols="12">
          <v-sheet color="section" class="pa-3 rounded">
            <v-row class="ma-0">
              <v-col cols="12"> <h3>Additional Options</h3> </v-col>
              <v-col cols="6">
                <div class="checkbox-width">
                  <checkbox-field
                    v-model="connection.customDomains"
                    dense
                    class="mt-0"
                    label="Custom Domains"
                  />
                </div>
              </v-col>
              <v-col v-if="!isForAgent" cols="6">
                <div class="checkbox-width">
                  <checkbox-field
                    v-model="connection.signer"
                    dense
                    class="mt-0"
                    label="Signer"
                  />
                </div>
              </v-col>
              <v-col v-if="user.isGroupTwoPlus" cols="6">
                <div class="checkbox-width">
                  <checkbox-field
                    v-model="connection.hidden"
                    dense
                    class="mt-0"
                    label="Hidden"
                  />
                </div>
              </v-col>
              <v-col cols="6">
                <div class="checkbox-width">
                  <checkbox-field
                    v-model="connection.limitEmailRecipients"
                    dense
                    class="mt-0"
                    label="Limit Email Recipients"
                  />
                </div>
              </v-col>
            </v-row>
          </v-sheet>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <app-button
        class="text-none"
        variant="outlined"
        color="grey"
        :loading="loading"
        @click="dialog.closeDialog()"
      >
        Cancel
      </app-button>
      <app-button
        class="text-none"
        color="primary"
        data-testid="save-connection"
        :loading="loading"
        @click="save"
      >
        Save
      </app-button>
    </v-card-actions>
  </v-card>
</template>

<script setup>
import ConnectionSettings from "@/components/shared/ConnectionSettings.vue";
import AdvisorSearch from "@/components/shared/AdvisorSearch.vue";
import { Connection } from "@/models/Connection";
import { computedValidation, parseErrorMessage } from "@/util/helpers";
import { updateConnection } from "@/api/connections.service";
import { createAgentConnection } from "@/api/agents.service";
import { createAgencyConnection } from "@/api/agencies.service";
import { useUserStore } from "@/stores/user";
import { useSnackbarStore } from "@/stores/snackbar";
import { useDialogStore } from "@/stores/dialog";
import { computed, ref, watch } from "vue";
import { mdiPlus } from "@mdi/js";
import useVuelidate from "@vuelidate/core";
const props = defineProps({
  creating: Boolean,
  isForAgent: Boolean,
  canAddSelf: Boolean,
  advisorName: { type: String, default: null },
  connectionToEdit: {
    type: Object,
    required: false,
    default: () => ({})
  },
  advisorId: {
    type: [Number, String],
    required: true
  },
  advisorType: {
    type: String,
    required: true
  }
});

const snackbar = useSnackbarStore();
const dialog = useDialogStore();
const user = useUserStore();

const advisor = ref(null);
const loading = ref(false);
const connection = ref(Connection(props.connectionToEdit));

const v$ = useVuelidate(
  {
    advisor: { required: v => !props.creating || Boolean(v?.id) }
  },
  { advisor },
  { $autoDirty: true, $scope: null }
);

watch(
  advisor,
  newVal => {
    if (newVal) {
      connection.value.connectedAdvisorId = newVal.id;
      connection.value.connectedAdvisorType = newVal.type;
      connection.value.connectedAdvisorName = newVal.name;
    }
  },
  { deep: true }
);
const connectionName = computed(() => {
  if (props.creating) return advisor.value?.name || "The advisor";
  return props.connectionToEdit.connectedAdvisorName || "The advisor";
});
const currentAdvisorIsSelf = computed(() => {
  return advisor.value?.id === user.loginable.id;
});

const advisorValidation = computedValidation(v$.value.advisor, {
  required: "Required"
});

function makeUserTheAdvisor() {
  advisor.value = {
    name: user.loginable.name,
    id: user.loginable.id,
    type: user.loginable.type
  };
}
async function save() {
  const isValid = await v$.value.$validate();

  if (!isValid) return;
  loading.value = true;
  snackbar.showInfoSnackbar({
    message: "Applying Changes...",
    duration: 2000
  });

  if (props.creating) return createConnection();
  return update();
}
async function createConnection() {
  if (props.creating) {
    let func = createAgencyConnection;

    if (props.advisorType.toLowerCase().includes("agent")) {
      func = createAgentConnection;
    }

    try {
      const newConnection = await func(props.advisorId, connection.value);
      loading.value = false;
      snackbar.showSuccessSnackbar({
        message: "Connection Successfully Created.",
        duration: 5000
      });
      dialog.closeDialog({ connection: newConnection });
    } catch (e) {
      loading.value = false;

      snackbar.showErrorSnackbar({
        message: parseErrorMessage(e),
        duration: -1
      });
    }
  }
}
async function update() {
  try {
    await updateConnection(props.connectionToEdit.id, connection.value);
    loading.value = false;
    snackbar.showSuccessSnackbar({
      message: "Connection Successfully Updated.",
      duration: 5000
    });

    dialog.closeDialog({ connection: connection.value });
  } catch (e) {
    loading.value = false;
    snackbar.showErrorSnackbar({
      message: parseErrorMessage(e),
      duration: -1
    });
  }
}
function initializeNewConnection() {
  connection.value.appointments = true;
  connection.value.appointmentsEmails = true;
  connection.value.cases = true;
  connection.value.casesEmails = true;
  connection.value.contractParties = true;
  connection.value.contractPartiesEmails = true;
  connection.value.customDomains = true;
  connection.value.electronicApplications = true;
  connection.value.electronicApplicationsEmails = true;
  connection.value.hierarchy = false;
  connection.value.payments = true;
  connection.value.paymentsEmails = true;
  connection.value.quickQuotes = true;
  connection.value.quickQuotesEmails = true;
  connection.value.quotes = true;
  connection.value.quotesEmails = true;
}

if (props.creating) initializeNewConnection();
</script>
